import React from 'react';
import { MessageCircle } from 'lucide-react';

const WhatsAppButton = () => {
  const message = encodeURIComponent('¡Hola! Me interesa vender mi catalizador, ¿podrían ayudarme?');
  
  return (
    <a
      href={`https://wa.me/525612684036?text=${message}`}
      target="_blank"
      rel="noopener noreferrer"
      className={`
        fixed bottom-6 right-6 z-50 bg-green-500 text-white px-4 py-3 rounded-full 
        shadow-lg hover:bg-green-600 transition-all duration-300 hover:scale-110 
        transform flex items-center gap-2 animate-shake-slow
      `}
      aria-label="Contactar por WhatsApp"
    >
      <MessageCircle size={24} />
      <span className="font-medium">Contáctanos</span>
    </a>
  );
};

export default WhatsAppButton;
